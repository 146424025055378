import Page from 'components/Page';
import React, { useCallback, useMemo, useState } from 'react'
import uploadIcon from '../icons/upload.svg';
import searchIcon from '../icons/search.svg';
import { debounce } from 'lodash';
import clsx from 'utils/clsx';
import { useAppSelector } from 'store/hooks';
import shopSelectors from 'store/shop/selectors';
import { uuid } from 'uuidv4';
import StaffMediaItem from './Item';
const genTempImage = () => ({
  type: 'image',
  id: uuid(),
  source: 'https://s3-alpha-sig.figma.com/img/3f07/843d/b89c398d8930638c88124486b26b89fb?Expires=1729468800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Pg7bOSxbH9TYJMaU4kbmtF2HhykaRHpg52arNM7r2l260yneBuD4zmaFKOn~~NNpAmVDCdweuXZfINXFGgbFU~w2owa08Tk4dQeOuemUcemihsUkkZ3b-mdtq7K-VblUNkYiMu08GzzqFqK5TgHL3TR0zTTBKsflG~oePOS68kvwYkLGeh9lrjHJzudG6zkR-W4lQYHd~-k8M9CWXlZD-DQxeV34w8ngR1hQY0j~aIM3hgyAHha7RYwxLQG2LQ7H2sJDnebElC89ixD7TUXyOq68l0AERIikKKlpAp-K~-hYotoe5-zdGtv5Ysf7KQ6FQebz2qZsiAbWen06sNfjpw__',
});
const genTempVideo = () => ({
  type: 'video',
  id: uuid(),
  source: 'https://pic.pikbest.com/00/87/40/80R888piCZwz.mp4',
});

const ImageReviewPage = () => {
  const _staffs = useAppSelector(shopSelectors.staff);
  console.log('_staffs', _staffs);

  const staffImages = useMemo(() => _staffs.map(o => ({
    id: o.id,
    name: o.name,
    avatar: o.avatar,
    files: [
      genTempImage(), genTempVideo(), genTempImage(),
      genTempVideo(), genTempImage(), genTempImage(),
      genTempImage(), genTempVideo(), genTempVideo(),
    ],
  })), [_staffs]);

  const [enableDelete, setEnableDelete] = useState(false);
  const ActionFooter = () => {
    return <div className='footer-actions-container'>
      {enableDelete ?
        <button className="btn-action btn-delete" onClick={() => setEnableDelete(false)}><span>Delete</span></button> :
        <button className="btn-action btn-choose-delete" onClick={() => setEnableDelete(true)}><span>Choose to delete</span></button>
      }
      <button className="btn-action btn-upload">
        <img src={uploadIcon} alt="uploadIcon" className="btn-icon" />
        <span>Upload Image</span>
      </button>
    </div>
  };

  return (
    <Page
      Sticky={() =>
        <div className="filter-container">
          <InputSearchDebounce onSearch={e => console.log(e)} />
        </div>}
      title={'Images'}
      containerClass={'image-gallery-content-kit'}
      StickFooter={ActionFooter}
        className={'image-gallery-content-wrapper'}
    >
      <div className="staff-list-container">
        {staffImages.map(o => (
          <StaffMediaItem key={o.id} data={o} />
        ))}
      </div>
    </Page>
  )
}

export default ImageReviewPage;

const InputSearchDebounce = ({ onSearch }) => {
  const [focus, setFocus] = useState(false);
  const [text, setText] = useState('');
  const _debounce = useCallback(debounce((text) => onSearch && onSearch(text), 200), [onSearch]);
  const onChange = (e) => {
    const _text = e.target.value?.trim();
    setText(_text);
    _debounce(_text);
  };
  return (
    <div className={clsx('input-search-container', { focus })}>
      <div className="input-search-selector">
        <img src={searchIcon} alt="searchIcon" className="search-icon" />
        <input value={text} onChange={onChange} placeholder='Staff name' type="text" className='input-search' onFocus={() => setFocus(true)} onBlur={() => setFocus(false)} />
      </div>
    </div>
  )
};
