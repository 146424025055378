import React from 'react'
import playIcon from '../icons/play.svg';
const StaffMediaItem = ({ data }) => {
  return (
    <div className='staff-media-item-container'>
      <div className="staff-avatar-container">
        <img className='image-avatar' src={data.avatar} alt={'avatar' + data.name} />
        <div className="staff-name">{data.name || 'A'}</div>
      </div>
      <div className="staff-images-container">
        {data.files.map(file => (
          <div key={file.id} className='media-item'>
            {file.type === 'video' ?
              <video src={file.source} muted className='media-video' /> :
              <img src={file.source} alt={file.source} className='media-image' />}
              {file.type === 'video' && <div className='video-overlay'><img src={playIcon} alt="playIcon" className='play-icon' /></div>}
          </div>
        ))}
      </div>
    </div>
  )
}

export default StaffMediaItem;
