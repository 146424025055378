import aptServiceReducer from "store/appt/reducers";
import customerServiceReducer from "store/customer/reducers";
import shopServiceReducer from "store/shop/reducers";

const rootReducer = {
  shop: shopServiceReducer,
  apt: aptServiceReducer,
  customer: customerServiceReducer,
};

export default rootReducer;
