import { all } from 'redux-saga/effects';
import aptServiceSagas from 'store/appt/sagas';
import customerServiceSagas from 'store/customer/sagas';
import shopServiceSagas, { initShopData } from 'store/shop/sagas';
export default function* rootSaga() {
  yield all([
    shopServiceSagas(),
    aptServiceSagas(),
    customerServiceSagas(),
  ]);
}
